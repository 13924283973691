$ = jQuery = require('jquery');
require('jquery.scrollto');
require('smartmenus');
require('smartmenus-keyboard');
require('slick-carousel');
require('jquery-nice-select');

// main javascript start
$(function() {
  var
    $window             = $(window),
    $body               = $('.js-html, .js-body'),
    $preloader          = $('.js-preloader'),
    $backtotop          = $('.js-backtotop'),
    $menuBtn            = $('.js-menu__btn'),
    $wrapper            = $('.js-wrapper'),
    $header             = $('.js-header'),
    $headerInfo         = $('.js-header-info'),
    $headerInfoRow      = $('.js-header-info__row'),
    $searchSelect       = $('.js-search-form__select'),
    $menu               = $('.js-menu__row'),
    $copyrightNow       = $('.js-copyright__now'),
    $contentBoxSlider   = $('.js-content-box-slider'),
    $tabBox             = $('.js-tab-box'),
    $collapseBox        = $('.js-collapse-box'),
    $or                 = $('.js-or'),
    $pr                 = $('.js-pr'),
    $adv                = $('.js-adv'),
    $advSupplier        = $('.js-adv-suppliers__item'),
    $noJSadvSupplierImg = $('.adv-suppliers__item img'),
    $tradeLead          = $('.js-trade-leads__item'),
    $toggleList         = $('.js-toggle-list'),
    $inputfile          = $('.js-inputfile'),
    $windowScroll       = 0,
    $lastScroll         = 0,
    $scrollUpStart      = 0;

  // show wrapper start
    $wrapper.removeAttr('style');
    $backtotop.removeAttr('style');
    $menuBtn.removeAttr('style');
  // show wrapper end

  // svg change xml start
    $('img[src$=".svg"]').each(function(){
      var
        $this    = $(this),
        imgID    = $this.attr('id'),
        imgClass = $this.attr('class'),
        imgURL   = $this.attr('src');

      $this.click(function(event) {
        event.preventDefault();
        alert();
      });

      $.get(imgURL, function(data) {
        var $svg = $(data).find('svg');

        // Add replaced image's ID to the new SVG
        if(typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }

        // Add replaced image's classes to the new SVG
        if(typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass+' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Check if the viewport is set, else we gonna set it if we can.
        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
        }

        $this.replaceWith($svg);
      }, 'xml');
    });
  // svg change xml end

  // preloader start
    $window.on('load', function(){
      $preloader.delay(600).fadeOut('slow');
    });
  // preloader end

  // backtotop start
    $backtotop.hide();

    $backtotop.click(function(e){
      $body.animate({ scrollTop: 0 }, {
        start: function() {
          $backtotop
            .addClass('backtotop--active');
        },
        complete: function() {
          $backtotop
            .removeClass('backtotop--active');
        }
      });
    }).scroll();

    $(window).scroll(function() {
      $windowScroll = $window.scrollTop();

      // if ($windowScroll <= $header.height()) {
      //   $wrapper.css('padding-top', 0);
      //   $header.removeClass('header--fixed');
      // } else if ($lastScroll > $windowScroll) {

      //   if ($scrollUpStart < $windowScroll) {
      //     $scrollUpStart = $windowScroll;
      //   }

      //   if ($scrollUpStart - $windowScroll > 300) {
      //     $wrapper.css('padding-top', $header.height());
      //     $header.addClass('header--fixed');
      //   }
      // } else {
      //   $scrollUpStart = 0;
      //   $wrapper.css('padding-top', 0);
      //   $header.removeClass('header--fixed');
      // }

      // $lastScroll = $windowScroll;

      if($windowScroll <= 400) {
        if ($backtotop.length) { $backtotop.hide(); }
      } else {
        if ($backtotop.length) { $backtotop.show(); }
      }
    });
  // backtotop end

  // header start
    // header menu start
    $window.resize(function(event) {
      headerInfoRowListFunction();
    });

    function headerInfoRowListFunction() {
      $headerInfoRow.smartmenus('destroy');

      if ($window.width() > 639) {
        $headerInfoRow.addClass('sm').removeClass('mobile');
        $headerInfo.removeClass('mobile');

        $headerInfoRow.smartmenus({
          subMenusSubOffsetX: 8,
          subMenusSubOffsetY: 8
        });
      } else {
        $headerInfoRow.removeClass('sm').addClass('mobile');
        $headerInfo.addClass('mobile');
      }
    }

    headerInfoRowListFunction();
    // header menu end
    // header select start
      $searchSelect.niceSelect();
    // header select end
  // header end


  // main menu start
    // menu init start
      $menu.smartmenus({
        subMenusSubOffsetX: 0,
        subMenusSubOffsetY: 0,
        markCurrentItem: true,
        arrowClicked: true,
        collapsibleShowFunction: function($ul, complete) {
          $ul.slideDown(200, function() {
            complete();
            $menu.scrollTo($ul.dataSM('parent-a'), 500);
          });
        }
      });
    // menu init end
    // menu-switch start
      $menuBtn.on({
        change: function(e) {
          if (this.checked) {
            $body.addClass('menu--open');
          } else {
            $body.removeClass('menu--open');
          }
        }
      });

      $(document).mouseup(function (event){
        if ($menuBtn.length &&
            $menuBtn.is(':checked') &&
            $wrapper.is(event.target)) {
          $menuBtn.click();
        }
      });
    // menu-switch end
  // main menu end

  // copyright year start
    if ($copyrightNow.length) {
      var d = new Date();
      var n = d.getFullYear();

      $copyrightNow.text(n);
    }
  // copyright year end

  // or slick start
    if ($or.length) {
      $or.slick({
        dots: true,
        fade: true
      });
    }
  // or slick end

  // content-box-slider slick start
    if ($contentBoxSlider.length) {
      $contentBoxSlider.slick({
        autoplay: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      });
    }
  // content-box-slider slick end

  // tab-box start
    if ($tabBox.length) {
      var active_tab = 'tab-1';

      $tabBox
        .find('.tab-box__list[tab-data='+active_tab+']')
        .addClass('tab-box__list--active')
        .parentsUntil('.content')
        .find('.tab-box__content[tab-content='+active_tab+']')
        .addClass('tab-box__content--active');


      $tabBox.find('.tab-box__list').click(function(event){
        var active_tab = $(this).attr('tab-data');

        // tab change
        $(this).siblings().removeClass('tab-box__list--active');
        $(this).addClass('tab-box__list--active');

        // content change
        $(this)
          .parentsUntil('.content')
          .find('.tab-box__content[tab-content='+active_tab+']')
          .addClass('tab-box__content--active')
          .siblings()
          .removeClass('tab-box__content--active');
      });
    }
  // tab-box end

  // collapse-box start
    if ($collapseBox.length) {
      $collapseBox
        .find('.collapse-box__content')
        .removeClass('collapse-box__content--active');

      $collapseBox
        .find('.collapse-box__head').click(function(event) {

          if ($(this).hasClass('collapse-box__head--active') === false) {
            $(this)
              .siblings('.collapse-box__head')
              .removeClass('collapse-box__head--active')
              .siblings('.collapse-box__content')
              .removeClass('collapse-box__content--active');
          }

          $(this)
            .toggleClass('collapse-box__head--active')
            .next('.collapse-box__content')
            .toggleClass('collapse-box__content--active');

          $body.animate({
            scrollTop: $(this).offset().top
          }, 500);
      });

      $collapseBox
        .find('.collapse-box__head:first-child')
        .addClass('collapse-box__head--active')
        .next('.collapse-box__content')
        .addClass('collapse-box__content--active');
    }
  // collapse-box end

  // inputfile start
    if ($inputfile.length) {
      $inputfile.addClass('js-inputfile--hidden');

      $inputfile.each(function() {
        var $this     = $(this),
            $sibling  = $this.next(),
            $filepath = $sibling.find('.filepath');

        $this.on({
          change: function(e) {
            var filename = '';

            if( this.files && this.files.length > 1 ) {
              filename = $this[0].files.length + ' files selected';
            } else {
              filename = e.target.value.split( '\\' ).pop();
            }

            if (filename) {
              $filepath.text(filename);
            }
          }
        });
      });
    }
  // inputfile end

  // remove advSupplier width & height start
  if ($noJSadvSupplierImg.length) {
    $noJSadvSupplierImg.removeAttr('width').removeAttr('height');
  }
  // remove advSupplier width & height end

  // ajax change start
    // load function start
      function loadAdvBanner() {
        $adv.load('/Banner/showHomeFlash.php?type=HomeFlash');
      }

      function loadPRBanner() {
        $pr.load('/Banner/showPR.php');
      }

      function loadSupplierBanner() {
        $advSupplier.load('/Banner/showSupplier.php');
      }

      function loadTradeLead() {
        $tradeLead.load('/Banner/showTradeLead.php');
      }
    // load function end

    $.ajaxSetup ({
      cache: false // Disable caching of AJAX responses
    });

    // setInterval start
      if ($adv.length) {
        loadAdvBanner();
        setInterval(loadAdvBanner, 6500);
      }

      if ($pr.length) {
        loadPRBanner();
        setInterval(loadPRBanner, 6500);
      }

      if ($advSupplier.length) {
        loadSupplierBanner();
        setInterval(loadSupplierBanner, 12000);
      }

      if ($tradeLead.length) {
        loadTradeLead();
        setInterval(loadTradeLead, 6500);
      }
    // setInterval end
  // ajax change end

  // toggleList start
    if ($toggleList.length) {
      $toggleList.click(function(event) {
        $toggleList.toggleClass('toggle-list--close')
        .next().slideToggle('400');
      }).click();
    }
  // toggleList end
});
// main javascript end